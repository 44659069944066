<template>
  <div>
    <!--搜索-->
    <!-- <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" :model="queryInfo">
            <el-form-item class="text" label="用户姓名">
              <el-input
                  placeholder="请输入用户姓名"
                  v-model="queryInfo.name"
                  size="small"
                  clearable>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" size="mini" type="primary" @click="refresh()">搜索</el-button>
              <el-button icon="el-icon-refresh" size="mini" @click="onReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row> -->
    <!--工具栏-->
    <el-row>
      <el-col :span="24">
        <div class="tools">
          <el-button @click="handleAdd" size="mini" icon="el-icon-plus" type="primary">新增背景图片</el-button>
          <el-button :loading="false" :disabled="false" size="mini" icon="el-icon-refresh" @click="refresh()">刷新</el-button>
        </div>
      </el-col>
    </el-row>
    <!--表格-->
    <el-row>
      <el-col :span="24">
        <div style="width: auto;">
          <el-table
              :data="tableData"
              stripe
              style="width: 100%;">
            <el-table-column
                type="selection"
                align="center"
                width="110">
            </el-table-column>
            <el-table-column
                prop="id"
                label="图片id"
                width="250">
            </el-table-column>

        <el-table-column label="图片" prop="url" align="center">
          <template slot-scope="scope">
            <el-image 
              style="width: 100px; height: 100px"
              :src="scope.row.url" 
              :preview-src-list="srcList" @click="addArray(scope)">
            </el-image>
          </template>
        </el-table-column>

              <el-table-column
                      prop="dimUrl"
                      label="模糊图片"
                      align="center">>
                <template slot-scope="scope">
                  <el-image 
                    style="width: 100px; height: 100px"
                    :src="scope.row.dimUrl" 
                    :preview-src-list="srcList" @click="addArray(scope)">
                  </el-image>
          </template>
              </el-table-column>
              <el-table-column
                      prop="createTime"
                      label="创建时间">
              </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                    size="mini"
                    type="text"
                    icon="el-icon-delete"
                    @click="handleDelete(scope.$index, scope.row)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 15px;text-align: right;">
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.page"
                :page-sizes="[10,20]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog
        title="新增背景图片"
        :visible.sync="addDialogVisible"
        width="50%">
      
        <el-upload
        class="upload-demo"
        :action="uploadUrl"
				:http-request="uploadImg"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
				accept=".jpg,.png,.jpeg"
        multiple
        :limit="1"
        :on-exceed="handleExceed"
        :file-list="fileList">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">只能上传jpg/jpeg/png文件</div>
      </el-upload>
      
      <span slot="footer" class="dialog-footer">
    <el-button @click="addDialogVisible = false">取 消</el-button>
    <!-- <el-button type="primary" @click="addDict()">确 定</el-button> -->
  </span>
    </el-dialog>
	
  </div>
</template>

<script>
import { uploadBackground } from '@/api/background.js'
import { queryBackground } from '@/api/background.js'
import { del } from '@/api/background.js'

export default {
  name: "Background",
  data() {
    return {
      fileList:[],
      tableData: [],
      srcList: [],
      queryInfo: {
        //当前的页数
        page: 1,
        //当前每页显示的多少条数据
        pageSize: 10
      },
      total: 0,
      addDialogVisible: false,
      editDialogVisible: false
    }
  },
  computed: {
    uploadUrl () {
      // 获取设置的代理网址
      // const base_url = process.env.NODE_ENV === "development" ? process.env.BASE_API : process.env.API_ROOT;
      // console.log(base_url, "网站地址")
      return process.env.VUE_APP_API +"api" + "/vc-background/upload";
    },
  },
  created () {
      this.refresh();
  },
  methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
      },
    onSubmit() {
      console.log('submit!');
    },
    onReset() {
      this.refresh();
    },
    handleAdd(){
      this.addDialogVisible=true;
    },
    addArray(scope) {
      this.srcList = []
      this.srcList.push(scope.row.url)
      this.srcList.push(scope.row.dimUrl)
    },
    refresh() {
      queryBackground(this.queryInfo).then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
          this.queryInfo.page = res.data.current
          this.queryInfo.pageSize = res.data.size
      })
    },
    uploadImg(data) {
        let formData =new FormData();
				formData.append("bgFile", data.file)
				uploadBackground(formData).then((res) => {
					if (res.code === 0) {
						this.$message.success("上传成功")
						this.addDialogVisible = false
						this.fileList = []
						this.refresh();
					} else {
						this.$message.error("上传失败，请稍后重试")
						this.addDialogVisible = false
						this.fileList = []
					}
				})
				
    },
    editDict() {
      
       const params = {
         name: undefined,
         value: undefined,
         id: this.editDictInfo.id,
         version: this.editDictInfo.version,
         code: undefined
       }

        if (this.editDictInfo.name !== '') {
          params.name = this.editDictInfo.name
          this.editDictInfo.name = ""
        } 
        if (this.editDictInfo.value !== '') {
          params.value = this.editDictInfo.value
          this.editDictInfo.code = ""
        } 
        if (this.editDictInfo.code !== '') {
          params.code = this.editDictInfo.code
          this.editDictInfo.code = ""
        } 

      editDictRequest(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("修改成功")
          this.refresh()
        } else {
          this.$message.error(res.msg)
        }
      })
      this.editDialogVisible=false;
      
    },
    handleEdit(index, row) {
      this.editDictInfo.id = row.id
      this.editDictInfo.version = row.version
      this.editDialogVisible=true;
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm('是否确认删除图片id为"' + row.id + '"的数据项??', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        del(row.id).then((res) => {
          if (res.code === 0) {
            this.$message.success("删除成功!")
						this.refresh();
          } else {
            this.$message.error(res.msg)
						this.refresh();
          }
        })
        
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.refresh();
    },
    handleCurrentChange(val) {
      this.queryInfo.page = val
      this.refresh();
    },
  }
}
</script>
<!--公共样式-->
<style scoped lang="scss">

</style>
